import Vapor from 'laravel-vapor'
import sanitizeHtml from 'sanitize-html'
import {
    now,
    dayDiff,
    shortFormat as dateShortFormat,
    longFormat as dateLongFormat,
    humanFormat as dateHumanFormat,
    humanLongFormat as dateLongHumanFormat,
    humanTimeFormat as dateTimeHumanFormat,
    humanLongTimeFormat as dateLongTimeHumanFormat,
    customDateFormat as dateCustomFormat,
} from '@tenant/utils/day'
import {
    formatSequence,
    currency,
    symbolCurrency,
    numberFormat,
} from '@tenant/utils/helper'

/**
 *
 * @param {number} bytes
 * @param {boolean} si
 *          True to use metric (SI) units, aka powers of 1000.
 *          False to use binary (IEC), aka powers of 1024.
 * @param {number} dp
 * @returns {string}
 */
const humanFileSize = (bytes, si = true, dp = 1) => {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    )

    return `${bytes.toFixed(dp)} ${units[u]}`
}

/**
 *
 * @param {date} date
 * @returns {string,number}
 */
const calculateExpiredDate = (date) => {
    if (new Date(date).getTime() > new Date().getTime()) {
        return Math.ceil(
            (date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)
        )
    }

    return dateShortFormat(date)
}

export const concat = (values = [], string = ' / ') => {
    return values.filter(Boolean).join(string)
}

const sanitize = (html) => {
    return sanitizeHtml(html, {
        allowedTags: false,
        allowedAttributes: false,
        allowVulnerableTags: true,
    })
}

const asset = Vapor.asset

export {
    now,
    dayDiff,
    dateShortFormat,
    dateLongFormat,
    dateTimeHumanFormat,
    dateLongTimeHumanFormat,
    dateHumanFormat,
    dateLongHumanFormat,
    dateCustomFormat,
    sanitize,
    humanFileSize,
    calculateExpiredDate,
    formatSequence,
    currency,
    numberFormat,
    symbolCurrency,
    asset,
}

export default (app) => {
    app.config.globalProperties.$filters = {
        sanitize,
        humanFileSize,
        calculateExpiredDate,
        now,
        dayDiff,
        dateShortFormat,
        dateLongFormat,
        dateTimeHumanFormat,
        dateLongTimeHumanFormat,
        dateHumanFormat,
        dateLongHumanFormat,
        dateCustomFormat,
        formatSequence,
        currency,
        numberFormat,
        symbolCurrency,
        concat,
        asset,
    }
}
